// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: infra/fleetconsole/api/fleetconsolerpc/service.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Empty } from "../../../../google/protobuf/empty.pb";

export const protobufPackage = "fleetconsole";

export enum DeviceType {
  DEVICE_TYPE_UNSPECIFIED = 0,
  DEVICE_TYPE_VIRTUAL = 1,
  DEVICE_TYPE_PHYSICAL = 2,
}

export function deviceTypeFromJSON(object: any): DeviceType {
  switch (object) {
    case 0:
    case "DEVICE_TYPE_UNSPECIFIED":
      return DeviceType.DEVICE_TYPE_UNSPECIFIED;
    case 1:
    case "DEVICE_TYPE_VIRTUAL":
      return DeviceType.DEVICE_TYPE_VIRTUAL;
    case 2:
    case "DEVICE_TYPE_PHYSICAL":
      return DeviceType.DEVICE_TYPE_PHYSICAL;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceType");
  }
}

export function deviceTypeToJSON(object: DeviceType): string {
  switch (object) {
    case DeviceType.DEVICE_TYPE_UNSPECIFIED:
      return "DEVICE_TYPE_UNSPECIFIED";
    case DeviceType.DEVICE_TYPE_VIRTUAL:
      return "DEVICE_TYPE_VIRTUAL";
    case DeviceType.DEVICE_TYPE_PHYSICAL:
      return "DEVICE_TYPE_PHYSICAL";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceType");
  }
}

export enum DeviceState {
  DEVICE_STATE_UNSPECIFIED = 0,
  /**
   * DEVICE_STATE_AVAILABLE - Default state.
   * Device is available to be leased.
   */
  DEVICE_STATE_AVAILABLE = 1,
  /** DEVICE_STATE_LEASED - Device is leased. */
  DEVICE_STATE_LEASED = 2,
}

export function deviceStateFromJSON(object: any): DeviceState {
  switch (object) {
    case 0:
    case "DEVICE_STATE_UNSPECIFIED":
      return DeviceState.DEVICE_STATE_UNSPECIFIED;
    case 1:
    case "DEVICE_STATE_AVAILABLE":
      return DeviceState.DEVICE_STATE_AVAILABLE;
    case 2:
    case "DEVICE_STATE_LEASED":
      return DeviceState.DEVICE_STATE_LEASED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceState");
  }
}

export function deviceStateToJSON(object: DeviceState): string {
  switch (object) {
    case DeviceState.DEVICE_STATE_UNSPECIFIED:
      return "DEVICE_STATE_UNSPECIFIED";
    case DeviceState.DEVICE_STATE_AVAILABLE:
      return "DEVICE_STATE_AVAILABLE";
    case DeviceState.DEVICE_STATE_LEASED:
      return "DEVICE_STATE_LEASED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceState");
  }
}

/** PingRequest intentionally contains nothing. */
export interface PingRequest {
}

/** PingResponse intentionally contains nothing. */
export interface PingResponse {
}

/** PingDeviceManagerRequest intentionally contains nothing. */
export interface PingDeviceManagerRequest {
}

/** PingDeviceManagerResponse intentionally contains nothing. */
export interface PingDeviceManagerResponse {
}

export interface Device {
  /**
   * In the case of VMs, device id could be the GCE instance name. For physical
   * DUTs we use the IDs we get from UFS.
   */
  readonly id: string;
  /** dut_id is the asset tag for a Device */
  readonly dutId: string;
  readonly address: DeviceAddress | undefined;
  readonly type: DeviceType;
  readonly state: DeviceState;
  /** // This proto contains per-DUT hardware relevant information including labels. */
  readonly deviceSpec: DeviceSpec | undefined;
}

export interface DeviceAddress {
  /**
   * IP address of the device.
   * For physical DUTs it can be a hostname.
   */
  readonly host: string;
  readonly port: number;
}

export interface ListDevicesRequest {
  /**
   * The maximum number of devices to return. The service may return fewer than
   * this value. If unspecified, at most 50 devices will be returned.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListDevices` call. Provide this to
   * retrieve the subsequent page. Returns first page if omitted.
   *
   * When paginating, all other parameters provided to `ListDevices` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  readonly orderBy: string;
  /** See: AIP-160 (https://google.aip.dev/160) for the syntax */
  readonly filter: string;
}

export interface ListDevicesResponse {
  /** List of devices to be returned. */
  readonly devices: readonly Device[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page. If
   * this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeviceSpec {
  /** A map for labels used to request a matching device. */
  readonly labels: { [key: string]: LabelValues };
}

export interface DeviceSpec_LabelsEntry {
  readonly key: string;
  readonly value: LabelValues | undefined;
}

export interface GetDeviceDimensionsResponse {
  readonly baseDimensions: { [key: string]: LabelValues };
  readonly labels: { [key: string]: LabelValues };
}

export interface GetDeviceDimensionsResponse_BaseDimensionsEntry {
  readonly key: string;
  readonly value: LabelValues | undefined;
}

export interface GetDeviceDimensionsResponse_LabelsEntry {
  readonly key: string;
  readonly value: LabelValues | undefined;
}

export interface LabelValues {
  readonly values: readonly string[];
}

function createBasePingRequest(): PingRequest {
  return {};
}

export const PingRequest: MessageFns<PingRequest> = {
  encode(_: PingRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingRequest {
    return {};
  },

  toJSON(_: PingRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingRequest>): PingRequest {
    return PingRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingRequest>): PingRequest {
    const message = createBasePingRequest() as any;
    return message;
  },
};

function createBasePingResponse(): PingResponse {
  return {};
}

export const PingResponse: MessageFns<PingResponse> = {
  encode(_: PingResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingResponse {
    return {};
  },

  toJSON(_: PingResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingResponse>): PingResponse {
    return PingResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingResponse>): PingResponse {
    const message = createBasePingResponse() as any;
    return message;
  },
};

function createBasePingDeviceManagerRequest(): PingDeviceManagerRequest {
  return {};
}

export const PingDeviceManagerRequest: MessageFns<PingDeviceManagerRequest> = {
  encode(_: PingDeviceManagerRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingDeviceManagerRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingDeviceManagerRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingDeviceManagerRequest {
    return {};
  },

  toJSON(_: PingDeviceManagerRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingDeviceManagerRequest>): PingDeviceManagerRequest {
    return PingDeviceManagerRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingDeviceManagerRequest>): PingDeviceManagerRequest {
    const message = createBasePingDeviceManagerRequest() as any;
    return message;
  },
};

function createBasePingDeviceManagerResponse(): PingDeviceManagerResponse {
  return {};
}

export const PingDeviceManagerResponse: MessageFns<PingDeviceManagerResponse> = {
  encode(_: PingDeviceManagerResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingDeviceManagerResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingDeviceManagerResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingDeviceManagerResponse {
    return {};
  },

  toJSON(_: PingDeviceManagerResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingDeviceManagerResponse>): PingDeviceManagerResponse {
    return PingDeviceManagerResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingDeviceManagerResponse>): PingDeviceManagerResponse {
    const message = createBasePingDeviceManagerResponse() as any;
    return message;
  },
};

function createBaseDevice(): Device {
  return { id: "", dutId: "", address: undefined, type: 0, state: 0, deviceSpec: undefined };
}

export const Device: MessageFns<Device> = {
  encode(message: Device, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.dutId !== "") {
      writer.uint32(50).string(message.dutId);
    }
    if (message.address !== undefined) {
      DeviceAddress.encode(message.address, writer.uint32(18).fork()).join();
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.state !== 0) {
      writer.uint32(32).int32(message.state);
    }
    if (message.deviceSpec !== undefined) {
      DeviceSpec.encode(message.deviceSpec, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Device {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevice() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.dutId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.address = DeviceAddress.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.deviceSpec = DeviceSpec.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Device {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      dutId: isSet(object.dutId) ? globalThis.String(object.dutId) : "",
      address: isSet(object.address) ? DeviceAddress.fromJSON(object.address) : undefined,
      type: isSet(object.type) ? deviceTypeFromJSON(object.type) : 0,
      state: isSet(object.state) ? deviceStateFromJSON(object.state) : 0,
      deviceSpec: isSet(object.deviceSpec) ? DeviceSpec.fromJSON(object.deviceSpec) : undefined,
    };
  },

  toJSON(message: Device): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.dutId !== "") {
      obj.dutId = message.dutId;
    }
    if (message.address !== undefined) {
      obj.address = DeviceAddress.toJSON(message.address);
    }
    if (message.type !== 0) {
      obj.type = deviceTypeToJSON(message.type);
    }
    if (message.state !== 0) {
      obj.state = deviceStateToJSON(message.state);
    }
    if (message.deviceSpec !== undefined) {
      obj.deviceSpec = DeviceSpec.toJSON(message.deviceSpec);
    }
    return obj;
  },

  create(base?: DeepPartial<Device>): Device {
    return Device.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Device>): Device {
    const message = createBaseDevice() as any;
    message.id = object.id ?? "";
    message.dutId = object.dutId ?? "";
    message.address = (object.address !== undefined && object.address !== null)
      ? DeviceAddress.fromPartial(object.address)
      : undefined;
    message.type = object.type ?? 0;
    message.state = object.state ?? 0;
    message.deviceSpec = (object.deviceSpec !== undefined && object.deviceSpec !== null)
      ? DeviceSpec.fromPartial(object.deviceSpec)
      : undefined;
    return message;
  },
};

function createBaseDeviceAddress(): DeviceAddress {
  return { host: "", port: 0 };
}

export const DeviceAddress: MessageFns<DeviceAddress> = {
  encode(message: DeviceAddress, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.host !== "") {
      writer.uint32(10).string(message.host);
    }
    if (message.port !== 0) {
      writer.uint32(16).int32(message.port);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceAddress {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceAddress() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.host = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.port = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceAddress {
    return {
      host: isSet(object.host) ? globalThis.String(object.host) : "",
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
    };
  },

  toJSON(message: DeviceAddress): unknown {
    const obj: any = {};
    if (message.host !== "") {
      obj.host = message.host;
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port);
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceAddress>): DeviceAddress {
    return DeviceAddress.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceAddress>): DeviceAddress {
    const message = createBaseDeviceAddress() as any;
    message.host = object.host ?? "";
    message.port = object.port ?? 0;
    return message;
  },
};

function createBaseListDevicesRequest(): ListDevicesRequest {
  return { pageSize: 0, pageToken: "", orderBy: "", filter: "" };
}

export const ListDevicesRequest: MessageFns<ListDevicesRequest> = {
  encode(message: ListDevicesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.orderBy !== "") {
      writer.uint32(26).string(message.orderBy);
    }
    if (message.filter !== "") {
      writer.uint32(34).string(message.filter);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDevicesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDevicesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.orderBy = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDevicesRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      orderBy: isSet(object.orderBy) ? globalThis.String(object.orderBy) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
    };
  },

  toJSON(message: ListDevicesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.orderBy !== "") {
      obj.orderBy = message.orderBy;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDevicesRequest>): ListDevicesRequest {
    return ListDevicesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDevicesRequest>): ListDevicesRequest {
    const message = createBaseListDevicesRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.orderBy = object.orderBy ?? "";
    message.filter = object.filter ?? "";
    return message;
  },
};

function createBaseListDevicesResponse(): ListDevicesResponse {
  return { devices: [], nextPageToken: "" };
}

export const ListDevicesResponse: MessageFns<ListDevicesResponse> = {
  encode(message: ListDevicesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.devices) {
      Device.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDevicesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDevicesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.devices.push(Device.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDevicesResponse {
    return {
      devices: globalThis.Array.isArray(object?.devices) ? object.devices.map((e: any) => Device.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListDevicesResponse): unknown {
    const obj: any = {};
    if (message.devices?.length) {
      obj.devices = message.devices.map((e) => Device.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDevicesResponse>): ListDevicesResponse {
    return ListDevicesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDevicesResponse>): ListDevicesResponse {
    const message = createBaseListDevicesResponse() as any;
    message.devices = object.devices?.map((e) => Device.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeviceSpec(): DeviceSpec {
  return { labels: {} };
}

export const DeviceSpec: MessageFns<DeviceSpec> = {
  encode(message: DeviceSpec, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.labels).forEach(([key, value]) => {
      DeviceSpec_LabelsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceSpec {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceSpec() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          const entry1 = DeviceSpec_LabelsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.labels[entry1.key] = entry1.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceSpec {
    return {
      labels: isObject(object.labels)
        ? Object.entries(object.labels).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
          acc[key] = LabelValues.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: DeviceSpec): unknown {
    const obj: any = {};
    if (message.labels) {
      const entries = Object.entries(message.labels);
      if (entries.length > 0) {
        obj.labels = {};
        entries.forEach(([k, v]) => {
          obj.labels[k] = LabelValues.toJSON(v);
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceSpec>): DeviceSpec {
    return DeviceSpec.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceSpec>): DeviceSpec {
    const message = createBaseDeviceSpec() as any;
    message.labels = Object.entries(object.labels ?? {}).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = LabelValues.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseDeviceSpec_LabelsEntry(): DeviceSpec_LabelsEntry {
  return { key: "", value: undefined };
}

export const DeviceSpec_LabelsEntry: MessageFns<DeviceSpec_LabelsEntry> = {
  encode(message: DeviceSpec_LabelsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      LabelValues.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceSpec_LabelsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceSpec_LabelsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = LabelValues.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceSpec_LabelsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? LabelValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DeviceSpec_LabelsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = LabelValues.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceSpec_LabelsEntry>): DeviceSpec_LabelsEntry {
    return DeviceSpec_LabelsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceSpec_LabelsEntry>): DeviceSpec_LabelsEntry {
    const message = createBaseDeviceSpec_LabelsEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? LabelValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseGetDeviceDimensionsResponse(): GetDeviceDimensionsResponse {
  return { baseDimensions: {}, labels: {} };
}

export const GetDeviceDimensionsResponse: MessageFns<GetDeviceDimensionsResponse> = {
  encode(message: GetDeviceDimensionsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.baseDimensions).forEach(([key, value]) => {
      GetDeviceDimensionsResponse_BaseDimensionsEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .join();
    });
    Object.entries(message.labels).forEach(([key, value]) => {
      GetDeviceDimensionsResponse_LabelsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeviceDimensionsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDimensionsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          const entry1 = GetDeviceDimensionsResponse_BaseDimensionsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.baseDimensions[entry1.key] = entry1.value;
          }
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          const entry2 = GetDeviceDimensionsResponse_LabelsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.labels[entry2.key] = entry2.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDimensionsResponse {
    return {
      baseDimensions: isObject(object.baseDimensions)
        ? Object.entries(object.baseDimensions).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
          acc[key] = LabelValues.fromJSON(value);
          return acc;
        }, {})
        : {},
      labels: isObject(object.labels)
        ? Object.entries(object.labels).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
          acc[key] = LabelValues.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: GetDeviceDimensionsResponse): unknown {
    const obj: any = {};
    if (message.baseDimensions) {
      const entries = Object.entries(message.baseDimensions);
      if (entries.length > 0) {
        obj.baseDimensions = {};
        entries.forEach(([k, v]) => {
          obj.baseDimensions[k] = LabelValues.toJSON(v);
        });
      }
    }
    if (message.labels) {
      const entries = Object.entries(message.labels);
      if (entries.length > 0) {
        obj.labels = {};
        entries.forEach(([k, v]) => {
          obj.labels[k] = LabelValues.toJSON(v);
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<GetDeviceDimensionsResponse>): GetDeviceDimensionsResponse {
    return GetDeviceDimensionsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDeviceDimensionsResponse>): GetDeviceDimensionsResponse {
    const message = createBaseGetDeviceDimensionsResponse() as any;
    message.baseDimensions = Object.entries(object.baseDimensions ?? {}).reduce<{ [key: string]: LabelValues }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = LabelValues.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.labels = Object.entries(object.labels ?? {}).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = LabelValues.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetDeviceDimensionsResponse_BaseDimensionsEntry(): GetDeviceDimensionsResponse_BaseDimensionsEntry {
  return { key: "", value: undefined };
}

export const GetDeviceDimensionsResponse_BaseDimensionsEntry: MessageFns<
  GetDeviceDimensionsResponse_BaseDimensionsEntry
> = {
  encode(
    message: GetDeviceDimensionsResponse_BaseDimensionsEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      LabelValues.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeviceDimensionsResponse_BaseDimensionsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDimensionsResponse_BaseDimensionsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = LabelValues.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDimensionsResponse_BaseDimensionsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? LabelValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetDeviceDimensionsResponse_BaseDimensionsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = LabelValues.toJSON(message.value);
    }
    return obj;
  },

  create(
    base?: DeepPartial<GetDeviceDimensionsResponse_BaseDimensionsEntry>,
  ): GetDeviceDimensionsResponse_BaseDimensionsEntry {
    return GetDeviceDimensionsResponse_BaseDimensionsEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<GetDeviceDimensionsResponse_BaseDimensionsEntry>,
  ): GetDeviceDimensionsResponse_BaseDimensionsEntry {
    const message = createBaseGetDeviceDimensionsResponse_BaseDimensionsEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? LabelValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseGetDeviceDimensionsResponse_LabelsEntry(): GetDeviceDimensionsResponse_LabelsEntry {
  return { key: "", value: undefined };
}

export const GetDeviceDimensionsResponse_LabelsEntry: MessageFns<GetDeviceDimensionsResponse_LabelsEntry> = {
  encode(message: GetDeviceDimensionsResponse_LabelsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      LabelValues.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeviceDimensionsResponse_LabelsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDimensionsResponse_LabelsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = LabelValues.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDimensionsResponse_LabelsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? LabelValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetDeviceDimensionsResponse_LabelsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = LabelValues.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<GetDeviceDimensionsResponse_LabelsEntry>): GetDeviceDimensionsResponse_LabelsEntry {
    return GetDeviceDimensionsResponse_LabelsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDeviceDimensionsResponse_LabelsEntry>): GetDeviceDimensionsResponse_LabelsEntry {
    const message = createBaseGetDeviceDimensionsResponse_LabelsEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? LabelValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseLabelValues(): LabelValues {
  return { values: [] };
}

export const LabelValues: MessageFns<LabelValues> = {
  encode(message: LabelValues, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.values) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LabelValues {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLabelValues() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.values.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LabelValues {
    return {
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: LabelValues): unknown {
    const obj: any = {};
    if (message.values?.length) {
      obj.values = message.values;
    }
    return obj;
  },

  create(base?: DeepPartial<LabelValues>): LabelValues {
    return LabelValues.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LabelValues>): LabelValues {
    const message = createBaseLabelValues() as any;
    message.values = object.values?.map((e) => e) || [];
    return message;
  },
};

export interface FleetConsole {
  /** Ping does not send or receive any information. It just checks that the service is there. */
  Ping(request: PingRequest): Promise<PingResponse>;
  /** PingDeviceManager attempts to contact DeviceManager through Fleet Console. Useful for debugging. */
  PingDeviceManager(request: PingDeviceManagerRequest): Promise<PingDeviceManagerResponse>;
  /** ListDevices managed by Device Manager. */
  ListDevices(request: ListDevicesRequest): Promise<ListDevicesResponse>;
  /** GetDeviceDimensions provides overview of devices dimensions and their values */
  GetDeviceDimensions(request: Empty): Promise<GetDeviceDimensionsResponse>;
}

export const FleetConsoleServiceName = "fleetconsole.FleetConsole";
export class FleetConsoleClientImpl implements FleetConsole {
  static readonly DEFAULT_SERVICE = FleetConsoleServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || FleetConsoleServiceName;
    this.rpc = rpc;
    this.Ping = this.Ping.bind(this);
    this.PingDeviceManager = this.PingDeviceManager.bind(this);
    this.ListDevices = this.ListDevices.bind(this);
    this.GetDeviceDimensions = this.GetDeviceDimensions.bind(this);
  }
  Ping(request: PingRequest): Promise<PingResponse> {
    const data = PingRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Ping", data);
    return promise.then((data) => PingResponse.fromJSON(data));
  }

  PingDeviceManager(request: PingDeviceManagerRequest): Promise<PingDeviceManagerResponse> {
    const data = PingDeviceManagerRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "PingDeviceManager", data);
    return promise.then((data) => PingDeviceManagerResponse.fromJSON(data));
  }

  ListDevices(request: ListDevicesRequest): Promise<ListDevicesResponse> {
    const data = ListDevicesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListDevices", data);
    return promise.then((data) => ListDevicesResponse.fromJSON(data));
  }

  GetDeviceDimensions(request: Empty): Promise<GetDeviceDimensionsResponse> {
    const data = Empty.toJSON(request);
    const promise = this.rpc.request(this.service, "GetDeviceDimensions", data);
    return promise.then((data) => GetDeviceDimensionsResponse.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
